.form-wrapper {

  .form-success-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-component {
    min-height: 350px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .form-check-input:checked {
    background-color: var(--brand-color);
    border-color: var(--brand-color);
  }

  .required {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    height: 0;
    overflow: hidden;
  }

  .form-label {
    font-size: 1rem;
  }

  .form-check-label {
    font-size: $font-size-sm;
  }

  .comment-element {
    .form-label {
      font-weight: $font-weight-bold;

      svg {
        margin-left: 6px;
        transition: transform .25s ease;

        &.comment-visible {
          transform: rotate(180deg);
        }
      }
    }
  }

  .submit {
    @include media-breakpoint-down(sm) {
      b {
        display: block;
      }
    }
  }

  .note {
    display: flex;

    .note-icon {
      opacity: 0.25;
      padding-right: 1rem;
      padding-top: 5px;
    }

    .note-text {
      font-size: $font-size-sm;
    }
  }

  .loader {
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
  }

  .processing {
    .loader {
      display: flex;
    }

    .form-row,
    .note {
      display: none;
    }
  }


}