// basic theme
$primary: #292A31;
$secondary: #f2f3f5;
$body-bg: #EFEFF2;
$body-color: #727280;
$headings-color: #292A31;
$colorOnBrand: #FFFFFF;
$btn-focus-box-shadow: none;
$input-focus-box-shadow: none;
$custom-control-indicator-focus-box-shadow: none;
$custom-select-focus-box-shadow: none;

  // Typography
$font-family-base: 'Quicksand';
$font-size-base: 1.125rem; // 18px
$font-size-sm: 14px;
$font-size-lg: $font-size-base * 1.2778;
$paragraph-margin-bottom: 1.5rem;
$h2-font-size: $font-size-base * 1.556;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base * 1.278;
$headings-font-weight: 700;
$headings-margin-bottom: 0;

// Buttons
$btn-border-radius: 0.556rem;
$btn-padding-y: .75rem ;
$btn-padding-x: 1.667rem;
$btn-line-height: 1.778rem;

// Forms
$input-font-size: 1rem;
$input-border-width: 0;
$input-padding-y: 0.778rem;
$input-padding-x: 1.111rem;

// Cards
$card-border-radius: 20px;
$card-bg: #FFFFFF;
$card-bg-rgb: 255,255,255;
$card-border-color-left: $card-bg;