* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--theme-font-family);
}

.App {
    background: url('../../assets/img/backgrounds/background.svg') no-repeat center center;
    background-size: cover;
    min-height: 100vh;

    @media (hover: hover) {
        & {
            background-attachment: fixed;
        }
    }
}

.large {
    font-size: $font-size-lg;
}

small, .small {
    font-size: $font-size-sm;
}