.App-footer {
  padding: 1rem 0 4rem;

  @include media-breakpoint-down(xl) {
    & {
      padding-bottom: 5rem;
    }
  }

  .list-inline-item {
    a {
      text-decoration: none;
      color: var(--bs-body-color);
    }
  }
}