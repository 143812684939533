.card-wrapper {
  //scroll-margin-top: 4em;

  .card {
    margin-bottom: 5rem;
    position: relative;
    transition: opacity 0.25s ease-in;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .card-inner {
      padding: 1rem;
      border-left: 10px solid var(--card-border-color);
      background-color: var(--card-bg);
      border-radius: var(--card-border-radius);
      position: relative;
      z-index: 20;
      transition: border-left 0.25s ease;

      &:after {
        content: "";
        position: absolute;
        left: -10px;
        top: 0;
        width: calc(100% + 10px);
        height: 100%;
        pointer-events: none;
        z-index: 15;
        box-shadow: 0 10px 20px rgba(0,0,0,1);
        opacity: 0.25;
        transition: box-shadow 0.25s ease;
        border-radius: var(--card-border-radius);
      }

      @include media-breakpoint-up(sm) {
        padding: 2rem;
      }

      @include media-breakpoint-down(xl) {
        & > .row {
          align-items: center;
        }
      }
    }

    &.active {
      .card-inner {
        border-left-color: var(--brand-color);

        &:after {
          opacity: 0.35;
        }
      }
    }
  }

  &:not(.form-wrapper) {
    .card {
      &:after {
        border-radius: 50%;
        content: "";
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: calc(50% - .5rem);
        top: calc(100% - .5rem);
        background-color: var(--brand-color);
        z-index: 10;
      }
    }

    .separator {
      width: 2px;
      height: 4.5rem;
      position: absolute;
      left: calc(50% - 1px);
      top: 100%;
      background-color: var(--brand-color);
      z-index: 10;
    }
  }

  &:not(.category-wrapper) {
    .card {
      &:before {
        border-radius: 50%;
        content: "";
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: calc(50% - .5rem);
        top: -0.5rem;
        background-color: var(--brand-color);
        z-index: 10;
      }
    }
  }
}