.category-wrapper {

  .card.success {
    pointer-events: none;
    opacity: 0.5;
  }

  .form-check {
    padding-left: 0;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      & {
        width: 100%;

        label {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}