// button outline primary
.btn-outline-primary {
  border-color: var(--brand-color);
  color: var(--brand-color);
}

.was-validated .form-check-input:valid~.btn-outline-primary {
  color: var(--brand-color);

  &:hover {
    color: var(--theme-color-on-brand);
  }
}

.was-validated .btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  box-shadow: none;
}

.btn-outline-primary:hover {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
}

// button primary
.btn-primary {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  box-shadow: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  filter: saturate(0.8);
  box-shadow: none;
}

.was-validated .btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  filter: saturate(0.5);
  opacity: 0.8;
  box-shadow: none;
}

.btn-secondary {
  background-color: #F2F3F5;
  border: #F2F3F5;
  color: #292A31;
  display: block;
  font-weight: 600;
}

.was-validated {
  .btn-secondary {
    color: #292A31;
  }
}

.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-color: var(--brand-color);
  box-shadow: none;
}

.was-validated .form-check-input:valid~.btn-secondary {
  color: #292A31;
}

.was-validated .btn-check:checked+.btn-secondary {
  color: var(--theme-color-on-brand);
}

.btn-shadow {

}